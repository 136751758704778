import { spacing, fontSize } from "../styles/mixins";
import { css } from "@emotion/react";
import { colours } from "./colours";

export const fontRoslindale = css`
	font-family: "Roslindale", "Helvetica", "Arial", sans-serif;
`;

export const fontRoslindaleDisplay = css`
	font-family: "Roslindale Display", "Helvetica", "Arial", sans-serif;
	word-spacing: 0.05em;
`;

export const fontSurt = css`
	font-family: "Surt", "Helvetica", "Arial", sans-serif;
	word-spacing: 0;
	letter-spacing: 0.01em;
`;

export const salty = css`
	font-feature-settings: "salt" on;
`;
export const noSalt = css`
	font-feature-settings: normal;
`;

export const fontBrixtonWood = css`
	font-family: "Brixton Wood", "Surt", "Helvetica", "Arial", sans-serif;
`;

export const displayH1 = css`
	${fontSize(7)};
	${fontRoslindaleDisplay};
	line-height: 0.9;
`;

export const displaySubtitle = css`
	${fontSize(1.5)};
	${fontSurt};
	line-height: 0.9;
`;

export const h1 = css`
	${fontSize(8.2)};
	${fontRoslindaleDisplay};
	font-weight: 400;
	line-height: 0.9;
`;

export const h2 = css`
	${fontSize(6.5)};
	${fontRoslindaleDisplay};
	line-height: 1.05;
	strong {
		${fontSurt};
		font-weight: normal;
	}
`;

export const h3 = css`
	${fontSize(5)};
	${fontRoslindaleDisplay};
	line-height: 1.1;
`;

export const h4 = css`
	${fontSize(4)};
	${fontRoslindaleDisplay};
	line-height: 1.1;
`;

export const h5 = css`
	${fontSize(1.1)};
	${fontRoslindaleDisplay};
	line-height: 1.1;
`;

export const h6 = css`
	${fontSize(2)};
	${fontRoslindaleDisplay};
	line-height: 0.9;
`;

export const outroTitle = css`
	${fontSize(6)};
	${fontRoslindaleDisplay};
	line-height: 1.05;
	strong {
		${fontSurt};
		font-weight: normal;
	}
`;

export const pMega = css`
	${fontSize(4)};
	${fontRoslindaleDisplay};
	line-height: 1.2;
`;

export const pEmphasis = css`
	${fontSize(2.5)};
	${fontSurt};
	line-height: 1.24;
`;

export const graphTitle = css`
	${fontSize(1.3)};
	${fontSurt};
	line-height: 1.15;
`;

export const pLead = css`
	${fontSize(2.9)};
	${fontRoslindaleDisplay};
	line-height: 1.15;
`;

export const pStandard = css`
	${fontSize(0)};
	${fontSurt};
	line-height: 1.4;
`;

export const meta = css`
	${fontSize(-1)};
	${fontSurt};
	line-height: 1.12;
`;

export const metaHeading = css`
	${fontSize(-1)};
	${fontRoslindaleDisplay};
	line-height: 1;
`;

export const display = css`
	${fontSize(10)};
	${fontRoslindaleDisplay};
`;

export const formTitle = css`
	${fontSize(2)};
	${fontSurt};
`;

export const pSmall = css`
	${fontSize(-0.5)};
	${fontSurt};
	line-height: 1;
`;

export const ul = css`
	${pStandard};
	list-style-type: disc;
`;

export const ol = css`
	${pStandard};
`;

export const embed = css`
	div[data-oembed] {
		padding-top: 56.25%;
		position: relative;

		iframe {
			position: absolute;
			width: 100%;
			top: 0;
			left: 0;
			height: 100%;
		}
	}
`;

export const richText = css`
	h1,
	.h1 {
		${h1};
		${spacing("marginTop", "xl")};
		${spacing("marginBottom", "l")};

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	h2,
	.h2 {
		${h2};
		${spacing("marginTop", "xl")};
		${spacing("marginBottom", "l")};

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	h3,
	.h3 {
		${h3};
		${spacing("marginTop", "l")};
		${spacing("marginBottom", "s")};

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	h4,
	.h4 {
		${h4};
		${spacing("marginTop", "l")};
		${spacing("marginBottom", "s")};

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	h5,
	.h5 {
		${h5};
		${spacing("marginTop", "l")};
		${spacing("marginBottom", "s")};

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	h6,
	.h6 {
		${h6};
		${spacing("marginTop", "l")};
		${spacing("marginBottom", "s")};

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	p {
		${pStandard};
		${spacing("marginBottom", "s")};

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	.meta {
		${meta};
		${spacing("marginBottom", "s")};

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}

		+ h2,
		+ .h2 {
			margin-top: 0;
		}
	}

	.p-small {
		${pSmall};
		${spacing("marginBottom", "s")};

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}

		+ h2,
		+ .h2 {
			margin-top: 0;
		}
	}

	ul {
		${ul};
	}

	ol {
		${ol};
	}

	img {
		max-width: 100%;
	}

	${embed};

	a,
	button {
		border-bottom: 1px solid rgba(0, 0, 0, 0.3);
		transition: border-color 400ms ease;

		&:hover {
			border-bottom: 1px solid rgba(0, 0, 0, 1);
			/* color: ${colours.highlight}; */
		}
	}

	button:focus {
		outline: none;
	}

	> :last-child {
		margin-bottom: 0;
	}
`;

export default pStandard;
